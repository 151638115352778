import { checkRedirectFlowOnRouteEnter } from './responseUtils';
import { storage } from './storage';
import {
  HUB_REDIRECT_FLOW,
  HUB_REDIRECT_FLOW_STATUS,
  PAYMENT_HUB_CURRENT_URL,
  STORAGE_3DS_DATA,
} from '../constants/paymentMethodsHub';

describe('Given the "responseUtils" method', () => {
  const originalWindowLocation = window.location;
  const originalWindowHistory = window.history;

  beforeEach(() => {
    Object.defineProperty(window, 'location', {
      writable: true,
      value: new URL(window.location.href),
    });

    Object.defineProperty(window, 'history', {
      writable: true,
      value: {
        pushState: (_data, _unused, newUrl) => {
          window.location.href = newUrl;
        },
      },
    });

    storage.remove(PAYMENT_HUB_CURRENT_URL);
    storage.remove(STORAGE_3DS_DATA);
    storage.remove(HUB_REDIRECT_FLOW);
  });

  afterEach(() => {
    Object.defineProperty(window, 'location', {
      writable: true,
      value: originalWindowLocation,
    });

    Object.defineProperty(window, 'history', {
      writable: true,
      value: originalWindowHistory,
    });
  });

  describe('When is called "checkRedirectFlowOnRouteEnter"', () => {
    describe('And is not a redirect', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;

        const to = {
          query: {
            status: '',
          },
          name: 'other',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then notRedirect is called', () => {
        expect(validator.validatorName).toBe('notRedirect');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be null', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(null);
      });

      it('Then PAYMENT_HUB_CURRENT_URL should be null', () => {
        expect(storage.get(PAYMENT_HUB_CURRENT_URL)).toBe(null);
      });
    });

    describe('And is coming from refresh', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;

        const to = {
          query: {
            status: '',
          },
          name: 'other',
        };
        storage.add(PAYMENT_HUB_CURRENT_URL, '/test');

        const url = 'http://testPlayground/test';
        window.location.href = url;

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then comingFromRefresh is called', () => {
        expect(validator.validatorName).toBe('comingFromRefresh');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be null', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(null);
      });

      it('Then PAYMENT_HUB_CURRENT_URL should be "/test"', () => {
        expect(storage.get(PAYMENT_HUB_CURRENT_URL)).toBe('/test');
      });
    });

    describe('And is a broken redirect', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;
        storage.add(STORAGE_3DS_DATA, 'redirectBroken');
        storage.add(HUB_REDIRECT_FLOW, 'redirectBrokenHub');

        const to = {
          query: {
            status: HUB_REDIRECT_FLOW_STATUS.toRedirect,
          },
          name: 'other',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then redirectLoopBroken is called', () => {
        expect(validator.validatorName).toBe('redirectLoopBroken');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be null', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(null);
      });
    });

    describe('And is a redirect loop finished', () => {
      let validator = null;

      beforeEach(() => {
        const from = {
          name: null,
        };
        storage.add(STORAGE_3DS_DATA, 'redirectLoopFinished');
        storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.toRedirect);

        const to = {
          query: {
            status: HUB_REDIRECT_FLOW_STATUS.fromRedirect,
          },
          name: 'other',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then redirectLoopFinished is called', () => {
        expect(validator.validatorName).toBe('redirectLoopFinished');
      });

      it('Then the STORAGE_3DS_DATA should be "redirectLoopFinished"', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe('redirectLoopFinished');
      });

      it('Then the HUB_REDIRECT_FLOW should be null', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(null);
      });
    });

    describe('And is a back on redirect finished', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;
        storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectStep0);

        const to = {
          query: {
            status: HUB_REDIRECT_FLOW_STATUS.redirectStep0,
          },
          name: 'other',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then backOnRedirectLoopFinished is called', () => {
        expect(validator.validatorName).toBe('backOnRedirectLoopFinished');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be null', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(null);
      });
    });

    describe('And is a redirect loop finished on edit booking', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;
        storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectStep0);

        const to = {
          query: {
            status: null,
          },
          name: 'bookings.myBookings.active',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then refreshUponFinished is called', () => {
        expect(validator.validatorName).toBe('refreshUponFinished');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be "redirectReSent"', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(HUB_REDIRECT_FLOW_STATUS.redirectReSent);
      });
    });

    describe('And is a redirect on edit booking re-sent', () => {
      let validator = null;

      beforeEach(() => {
        const from = null;
        storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectStep0);

        const to = {
          query: {
            status: HUB_REDIRECT_FLOW_STATUS.fromRedirect,
          },
          name: 'bookings.myBookings.active',
        };

        validator = checkRedirectFlowOnRouteEnter(from, to);
      });

      it('Then resentFromRedirectPage is called', () => {
        expect(validator.validatorName).toBe('resentFromRedirectPage');
      });

      it('Then the STORAGE_3DS_DATA should be null', () => {
        expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
      });

      it('Then the HUB_REDIRECT_FLOW should be "redirectReSent"', () => {
        expect(storage.get(HUB_REDIRECT_FLOW)).toBe(HUB_REDIRECT_FLOW_STATUS.redirectReSent);
      });
    });

    describe('And is a redirect from external re-sent', () => {
      describe('Then the resentExternalRedirectPage is called', () => {
        let validator = null;

        beforeEach(() => {
          const from = null;
          storage.add(HUB_REDIRECT_FLOW, HUB_REDIRECT_FLOW_STATUS.redirectStep0);

          const to = {
            query: {
              status: HUB_REDIRECT_FLOW_STATUS.fromRedirect,
            },
            name: 'other',
          };

          validator = checkRedirectFlowOnRouteEnter(from, to);
        });

        it('Then resentExternalRedirectPage is called', () => {
          expect(validator.validatorName).toBe('resentExternalRedirectPage');
        });

        it('Then the STORAGE_3DS_DATA should be null', () => {
          expect(storage.get(STORAGE_3DS_DATA)).toBe(null);
        });

        it('Then the HUB_REDIRECT_FLOW should be "redirectReSent"', () => {
          expect(storage.get(HUB_REDIRECT_FLOW)).toBe(HUB_REDIRECT_FLOW_STATUS.redirectReSent);
        });
      });
    });
  });
});
